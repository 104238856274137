.l6okjhz{list-style:none;font-size:18px;letter-spacing:0.5px;color:#383838;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;background-color:var(--l6okjhz-0);padding-bottom:16px;gap:12px;position:relative;border-bottom:var(--l6okjhz-1);}.l6okjhz .cover-image{position:relative;width:126px;height:88px;border-radius:6px;overflow:hidden;background-color:#e2e8f1;margin-top:4px;}.l6okjhz .cover-image::after{content:'';position:absolute;top:0;left:0;right:0;bottom:0;background-image:var(--l6okjhz-2);background-image:'';-webkit-background-size:cover;background-size:cover;-webkit-background-position:center;background-position:center;-webkit-transition:0.4s;transition:0.4s;}.l6okjhz:hover .cover-image::after{-webkit-transform:scale(1.1);-moz-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1);}@media screen and (max-width: 1024px){.l6okjhz{background-color:var(--l6okjhz-3);padding:var(--l6okjhz-4);}}
.c1buaxct{-webkit-text-decoration:none;text-decoration:none;color:#383838;-webkit-flex:1;-ms-flex:1;flex:1;}.c1buaxct :hover{color:black;}
.t2a6dmk{font-size:20px;line-height:1.5;color:#383838;margin-bottom:10px;display:-webkit-box;-webkit-line-clamp:var(--t2a6dmk-0);-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;cursor:pointer;}@media screen and (max-width: 1024px){.t2a6dmk{-webkit-line-clamp:var(--t2a6dmk-1);}}
.tlhuwq2 :hover{color:black;}.tlhuwq2 :visited{color:rgba(115, 115, 115, 0.5);}
.n1hj6r9n{color:#848d97;margin-right:20px;}
.c1m5ajah{font-size:13px;margin-bottom:15px;line-height:13px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.c1m5ajah span{color:#515c69;position:relative;}.c1m5ajah span::before{position:absolute;content:'';display:block;width:4px;height:4px;left:-12px;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);border-radius:2px;background-color:#e2e8f1;}
.hog4kpj{position:absolute;left:-42px;top:24px;}
.s15ftoqn{font-size:14px;line-height:1.71;color:#777777;margin-bottom:10px;display:-webkit-box;-webkit-line-clamp:var(--s15ftoqn-0);-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;}
.f13m4t48{width:24px;height:24px;border-radius:50%;background:#ccc none 50% no-repeat;-webkit-background-size:cover;background-size:cover;overflow:hidden;}.f13m4t48 img{z-index:1;width:24px;height:24px;object-fit:contain;object-position:center;}
.cf9m4lf{margin-left:7.5px;font-size:14px;color:#383838;}.cf9m4lf :hover{color:#54b5c6;}@media screen and (max-width: 1024px){.cf9m4lf{max-width:150px;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;}}
.dlq02zg{background-color:#eaeef5;width:2px;height:16px;margin:0 8px;}
