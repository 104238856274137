.l1a4qkhm{list-style:none;font-size:18px;letter-spacing:0.5px;color:#383838;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;background-color:var(--l1a4qkhm-0);padding-top:var(--l1a4qkhm-1);padding-bottom:16px;gap:12px;position:relative;border-bottom:var(--l1a4qkhm-2);height:calc(100% - 16px);}
.cr1s9zi{-webkit-text-decoration:none;text-decoration:none;color:#383838;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.cr1s9zi :hover{color:black;}
.t1hzlwjr{font-size:20px;line-height:24px;color:#383838;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;margin-bottom:5px;}.t1hzlwjr:hover{color:#000;}.t1hzlwjr:visited{color:rgba(56, 56, 56, 0.7);}.t1hzlwjr mark{color:#e03f19;background-color:inherit;}@media screen and (max-width: 1024px){.t1hzlwjr{font-size:18px;line-height:26px;-webkit-line-clamp:2;}}
.s1t92iim{font-size:14px;line-height:24px;color:#777777;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;margin-bottom:8px;}.s1t92iim mark{color:#e03f19;background-color:white;}@media screen and (max-width: 1024px){.s1t92iim{-webkit-line-clamp:2;line-height:22px;}}
.n9y2vsf{color:#848d97;margin-right:20px;}
.ch0d6kp{font-size:13px;margin-bottom:10px;line-height:13px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.b175cfka{color:#515c69;position:relative;}.b175cfka::before{position:absolute;content:'';display:block;width:4px;height:4px;left:-12px;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);border-radius:2px;background-color:#e2e8f1;}
.d1bwdc5g{background-color:#eaeef5;width:2px;height:16px;margin:0 8px;}
.f1xx2ryd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:24px;height:24px;border-radius:50%;background:#ccc none 50% no-repeat;-webkit-background-size:cover;background-size:cover;overflow:hidden;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;border:solid 1px #e2e8f1;}.f1xx2ryd img{z-index:1;height:100%;display:block;}
.c170t2g{margin-left:7.5px;font-size:14px;color:#383838;}.c170t2g :hover{color:#54b5c6;}
